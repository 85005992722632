import { useState } from 'react'
import { Dialog, DialogPanel, Popover } from '@headlessui/react'
import { Bars3Icon, XMarkIcon, CheckIcon, InboxIcon, TrashIcon, UsersIcon, MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'
import PushableButtonWithStyles from "~/components/PushableButton";
import clsx from 'clsx';
import { FaRobot, FaHeadSideVirus, FaCreditCard } from "react-icons/fa6";
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react'
import { MetaFunction } from '@remix-run/node';
import { useHydrated } from "remix-utils/use-hydrated";

import { type LoaderFunctionArgs } from '@remix-run/node'
import { createServerClient, parseCookieHeader, serializeCookieHeader } from '@supabase/ssr'
import { Resource } from "sst/resource";

export async function loader({ request }: LoaderFunctionArgs) {
  const headers = new Headers()

  const supabase = createServerClient(Resource.SupabaseUrl.value, Resource.SupabaseKey.value, {
    cookies: {
      getAll() {
        return parseCookieHeader(request.headers.get('Cookie') ?? '')
      },
      setAll(cookiesToSet) {
        cookiesToSet.forEach(({ name, value, options }) =>
          headers.append('Set-Cookie', serializeCookieHeader(name, value, options))
        )
      },
    },
  })

  return new Response('...', {
    headers,
  })
}

export const meta: MetaFunction = () => {
  return [
    { title: "SkillShop - Lists that Make You Money" },
    {
      property: "og:title",
      content: "SkillShop - Lists that Make You Money",
    },
    {
      name: "description",
      content: "Transform your expertise into profitable task lists. Create your SkillShop, showcase your skills, and start earning today.",
    },
    {
      property: "og:description",
      content: "Transform your expertise into profitable task lists. Create your SkillShop, showcase your skills, and start earning today.",
    },
    {
      property: "og:image",
      content: "https://www.skillshop.pro/og-skillshop.jpg",
    },
    {
      property: "og:url",
      content: "https://www.skillshop.pro",
    },
    {
      property: "og:type",
      content: "website",
    },
    // Twitter Card Tags
    {
      name: "twitter:card",
      content: "summary_large_image",
    },
    {
      name: "twitter:title",
      content: "SkillShop - Lists that Make You Money",
    },
    {
      name: "twitter:description",
      content: "Transform your expertise into profitable task lists. Create your SkillShop, showcase your skills, and start earning today.",
    },
    {
      name: "twitter:image",
      content: "https://www.skillshop.pro/x-skillshop.jpg",
    },
  ];
};

const navigation = [
  { name: 'Product', href: '#product' },
  { name: 'FAQs', href: '#faqs' },
  { name: 'Pricing', href: '#pricing' },
]

export default function Index() {
  return (
    <MktLayout>
      <Hero />

      <Section id="examples">
        <SectionHeader
          subtitle="SkillShops in the real world"
          title={<>These folks know <span className='whitespace-nowrap'>what's up</span></>}
          description={<>Checkout the <GradientText text="SkillShops" className="text-2xl font-bold leading-9 inline" /> they created.</>}
        />
        <Examples />
      </Section>

      <Section id="product">
        <SectionHeader color='dark' textAlign='left' className='!bg-black' subtitle='What are SkillShops?' title={<>How Your Lists Become <br />Money Makers</>} description={
          <>
            <GradientText text="SkillShops" className="text-2xl font-bold leading-9 inline" /> aren't your typical job marketplace listing or ordinary online store.
            They are lead magnets for your skills. Built from your task lists, they offer{' '}
            <TooltipText items={[
              {
                term: "DIY",
                definition: "Do It Yourself",
                explanation: "Clients follow your list to complete tasks independently."
              },
              {
                term: "DWY",
                definition: "Done With You",
                explanation: "Collaborate with clients, offering guidance and support throughout the process."
              },
              {
                term: "DFY",
                definition: "Done For You",
                explanation: "You complete the entire task or project on behalf of the client."
              }
            ]}>DIY, DWY, and DFY options</TooltipText>, elevating your skills
            showcase into a powerful business tool.
          </>
        } />
        <VersusComparison />
      </Section>

      <Section id="benefits">
        <SectionHeader color='dark' textAlign='center' className='!bg-black !pt-4' subtitle='Become a recognized expert' title={<>Build Your Personal Brand on Expertise</>} description={
          <>
            <GradientText text="SkillShops" className="text-2xl font-bold leading-9 inline" /> transform your knowledge into a powerful personal brand. Our lists and suite of tools help you showcase your expertise, engage your audience, and establish yourself as a credible voice in your industry.
          </>
        } />
        <Tools />
      </Section>
      <Pricing id="pricing" />
      <FAQs id="faqs" />
      <Guarantee />
      <CTA />
    </MktLayout>
  )
}

function Header() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  const handleNavClick = (e: React.MouseEvent<HTMLAnchorElement>, href: string) => {
    e.preventDefault();
    setMobileMenuOpen(false);
    const element = document.querySelector(href);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <header className="absolute inset-x-0 top-0 z-50">
      <nav aria-label="Global" className="flex items-center justify-between p-6 lg:px-8">
        <div className="flex lg:flex-1">
          <a href="#" className="-m-1.5 p-1.5">
            <span className="sr-only">SkillShop</span>
            <img
              alt=""
              src="/logo-skillshop-dark-bkg.svg"
              className="h-12 w-auto"
            />
          </a>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            onClick={() => setMobileMenuOpen(true)}
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-400"
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon aria-hidden="true" className="h-6 w-6" />
          </button>
        </div>
        <div className="hidden lg:flex lg:gap-x-12">
          {navigation.map((item) => (
            <a
              key={item.name}
              href={item.href}
              className="text-sm font-semibold leading-6 text-white"
              onClick={(e) => handleNavClick(e, item.href)}
            >
              {item.name}
            </a>
          ))}
        </div>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          <Link to="/login" className="text-sm font-semibold leading-6 text-white">
            Log in
          </Link>
        </div>
      </nav>
      <Dialog open={mobileMenuOpen} onClose={setMobileMenuOpen} className="lg:hidden">
        <div className="fixed inset-0 z-50" />
        <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-gray-900 px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-white/10">
          <div className="flex items-center justify-between">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">SkillShop</span>
              <img
                alt=""
                src="/logo-skillshop-dark-bkg.svg"
                className="h-12 w-auto"
              />
            </a>
            <button
              type="button"
              onClick={() => setMobileMenuOpen(false)}
              className="-m-2.5 rounded-md p-2.5 text-gray-400"
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon aria-hidden="true" className="h-6 w-6" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/25">
              <div className="space-y-2 py-6">
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-800"
                    onClick={(e) => handleNavClick(e, item.href)}
                  >
                    {item.name}
                  </a>
                ))}
              </div>
              <div className="py-6">
                <Link
                  to="/login"
                  className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-white hover:bg-gray-800"
                >
                  Log in
                </Link>
              </div>
            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </header>
  )
}

function Hero() {
  const navigate = useNavigate();
  return (
    <div className="bg-black">

      <div className="relative isolate pt-14">
        <div
          aria-hidden="true"
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
        >
          <div
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
          />
        </div>
        <div className="py-24 sm:py-32 lg:pb-40">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl text-center">
              <h1 className="text-6xl font-bold tracking-tight text-white sm:text-8xl">
                Lists that Make You Money
              </h1>
              <div className="mt-6 text-lg leading-8 text-gray-400">
                Get your <GradientText text="SkillShop" className="text-2xl font-bold leading-9 inline" /> up and running in minutes. Tell our AI what you do for a living, <span className='whitespace-nowrap'>refine the tasks</span> <span className='whitespace-nowrap'>& begin selling</span>.
              </div>
              <div className="mt-10 flex items-center justify-center gap-x-6">
                <PushableButtonWithStyles onClick={(e) => {
                  e.preventDefault();
                  navigate("/register");
                }}>
                  Start now - FREE
                </PushableButtonWithStyles>
              </div>
            </div>
            <img
              alt="App screenshot"
              src="/images/astronaut-hero.webp"
              width={2432}
              height={1442}
              className="mt-16 rounded-md bg-white/5 shadow-2xl ring-1 ring-white/10 sm:mt-24"
            />
          </div>
        </div>
        <div
          aria-hidden="true"
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
        >
          <div
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
          />
        </div>
      </div>
    </div>
  )
}

export function Section({ children, id }: { children: React.ReactNode, id?: string }) {
  return (
    <div id={id} className="bg-black px-6">
      {children}
    </div>
  )
}

export function SectionHeader({ subtitle, title, description, className, color = 'light', textAlign = 'center' }: { subtitle: string, title: React.ReactNode, description?: React.ReactNode, className?: string, color?: 'light' | 'dark', textAlign?: 'left' | 'center' | 'right' }) {
  return (
    <div className={clsx('bg-white px-0 pt-16 pb-12 sm:pt-24 lg:px-8 rounded-t-3xl', className)}>
      <div className={clsx({
        'mx-auto max-w-6xl': textAlign === 'left' || textAlign === 'right',
        'mx-auto max-w-2xl': textAlign === 'center',
        'text-left': textAlign === 'left',
        'text-center': textAlign === 'center',
        'text-right': textAlign === 'right'
      })}>
        <p className="text-base font-semibold leading-7 text-[#f0003c]">{subtitle}</p>
        <h2 className={clsx('mt-2 text-4xl font-bold tracking-tight sm:text-6xl', {
          'text-gray-900': color === 'light',
          'text-white': color === 'dark'
        })}>{title}</h2>
        {description && (
          <div className={clsx('mt-6 text-lg leading-8 max-w-xl', {
            'text-gray-600': color === 'light',
            'text-gray-400': color === 'dark',
            'mx-auto': textAlign === 'center',
            '!max-w-2xl': textAlign === 'center',
          })}>
            {description}
          </div>
        )}
      </div>
    </div>
  )
}

export function Examples() {
  const exampleData = [
    {
      image: "/images/logo-launchpad.webp",
      title: "Greg's Validation Launchpad",
      description: "Don't waste time and money on bad ideas. Validate them.",
      avatar: "/images/customer-isra.jpg",
      username: "Isra Pasos",
      link: "/launchpad",
    },
    {
      image: "/images/logo-email.webp",
      title: "Krystal's Email Marketing Kit",
      description: "Stop sending emails that suck. Elevate your email game.",
      avatar: "/images/customer-krystal.webp",
      username: "Krystal Ariel",
      link: "/launchpad",
    },
    {
      image: "/images/logo-playbook.webp",
      title: "Codie's SaaS Marketing Playbook",
      description: "Marketing activities that all SaaS companies should do.",
      avatar: "/images/customer-stephen.jpg",
      username: "Stephen Hertzberg",
      link: "/launchpad",
    },
  ];

  return (
    <div className="bg-white pb-4 sm:pb-24 rounded-b-3xl">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="relative isolate overflow-hidden bg-black px-6 py-6 sm:py-14 text-left shadow-2xl rounded-3xl sm:px-16">

          {/* Grid inserted here */}
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
            {exampleData.map((example, index) => (
              <a
                key={index}
                href={example.link}
                className="bg-white/10 rounded-lg p-6 block hover:bg-white/20 transition-colors duration-200"
              >
                <img src={example.image} alt={example.title} className="w-full h-48 object-cover rounded-lg mb-4" />
                <h3 className="text-lg font-normal text-white mb-2 leading-6">{example.title}</h3>
                <p className="text-sm text-gray-400 mb-4">{example.description}</p>
                <div className="flex items-center">
                  <img src={example.avatar} alt={example.username} className="w-10 h-10 rounded-full mr-3 border-[1px] border-white/50" />
                  <span className="text-white">{example.username}</span>
                </div>
              </a>
            ))}
          </div>

          <svg
            viewBox="0 0 1024 1024"
            aria-hidden="true"
            className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)]"
          >
            <circle r={512} cx={512} cy={512} fill="url(#827591b1-ce8c-4110-b064-7cb85a0b1217)" fillOpacity="0.7" />
            <defs>
              <radialGradient id="827591b1-ce8c-4110-b064-7cb85a0b1217">
                <stop stopColor="#7775D6" />
                <stop offset={1} stopColor="#E935C1" />
              </radialGradient>
            </defs>
          </svg>
        </div>
      </div>
    </div>
  )
}

import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline'

function VersusComparison() {
  const skillCommerceFeatures = [
    'Easier to differentiate',
    'Own your client relationships',
    'Higher profit margins',
    'DIY, DWY, and DFY options',
    'Build your personal brand',
  ]

  const marketplaceFeatures = [
    'Race to the bottom pricing',
    'High platform fees (up to 20%)',
    'They own client relationships',
    'Restrictive terms of service',
    'Oversaturated with competition',
  ]

  return (
    <div className="bg-black pb-12">
      <div className="mx-auto max-w-7xl px-0 lg:px-8">
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-3">
          {/* Skill Commerce */}
          <div className="rounded-2xl bg-white/5 p-8 ring-1 ring-white/10">
            <GradientText text="SkillShops" className="text-3xl font-bold leading-9" />
            {/* <h3 className="text-3xl font-bold leading-9 text-white">SkillShops</h3> */}
            <ul className="mt-4 space-y-3">
              {skillCommerceFeatures.map((feature, index) => (
                <li key={index} className="flex gap-x-3">
                  <CheckIcon className="h-6 w-5 flex-none text-indigo-400" aria-hidden="true" />
                  <span className="text-sm leading-6 text-gray-300">{feature}</span>
                </li>
              ))}
            </ul>
          </div>

          {/* VS Graphic */}
          <div className="flex items-center justify-center">
            <div className="text-6xl font-extrabold text-transparent bg-clip-text bg-white to-red-600 transform -rotate-12 shadow-lg p-4">
              VS
            </div>
          </div>

          {/* Marketplaces */}
          <div className="rounded-2xl bg-white/5 p-8 ring-1 ring-white/10">
            <h3 className="text-3xl font-bold leading-9 text-white">Marketplaces</h3>
            <ul className="mt-4 space-y-3">
              {marketplaceFeatures.map((feature, index) => (
                <li key={index} className="flex gap-x-3">
                  <XMarkIcon className="h-6 w-5 flex-none text-red-500" aria-hidden="true" />
                  <span className="text-sm leading-6 text-gray-300">{feature}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

interface TooltipItem {
  term: string;
  definition: string;
  explanation: string;
}

function TooltipText({ children, items }: { children: React.ReactNode, items: TooltipItem[] }) {
  return (
    <Popover className="relative inline-block">
      <Popover.Button className="text-white border-b border-dotted border-white hover:text-gray-200 focus:outline-none">
        {children}
      </Popover.Button>
      <Popover.Panel className="absolute z-10 w-72 p-4 mb-2 bg-white rounded-md shadow-lg bottom-full left-1/2 transform -translate-x-1/2">
        <div className="text-sm text-gray-700">
          {items.map((item, index) => (
            <div key={index} className="mb-2">
              <p><strong>{item.term}:</strong> {item.definition}</p>
              <p className="text-xs text-gray-500">{item.explanation}</p>
            </div>
          ))}
        </div>
        <div className="absolute w-3 h-3 bg-white transform rotate-45 left-1/2 -translate-x-1/2 bottom-[-6px]"></div>
      </Popover.Panel>
    </Popover>
  )
}

const tools = [
  {
    title: "Expert List Generator",
    description: "Effortlessly create profitable lists with SkillShop's AI, tailored to maximize your expertise.",
    image: "/images/astronaut-generator.webp",
    alt: "Become an expert",
    bgColor: "bg-[#783afb]",
  },
  {
    title: "Task Refiner",
    description: "Refine your potential tasks with AI-powered suggestions, perfectly aligned with your skills and expertise.",
    image: "/images/astronaut-refinerb.webp",
    alt: "Task Refiner",
    bgColor: "bg-[#ff007c]",
  },
  {
    title: "Engagement Booster",
    description: "Leverage AI to craft compelling content based on your tasks and expertise. Building your credibility and reputation effortlessly.",
    image: "/images/astronaut-expert.webp",
    alt: "Engagement Booster",
    bgColor: "bg-[#6593bd]",
  },
  {
    title: "Client Manager",
    description: "Easily manage client projects and tasks with our intuitive kanban-style CRM.",
    image: "/images/astronaut-manager.webp",
    alt: "Client Management",
    bgColor: "bg-[#f8b500]",
  },
];

function Tools() {
  return (
    <div className="bg-transparent pb-24 rounded-b-3xl">
      <div className="mx-auto max-w-7xl px-0 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-10">
          {tools.map((tool, index) => (
            <div key={index} className={`${tool.bgColor} px-6 py-6 text-left rounded-3xl overflow-hidden flex flex-col sm:flex-row items-center`}>
              <div className="w-full sm:w-2/3 sm:pr-4 mb-4 sm:mb-0">
                <h2 className='text-white text-2xl font-medium mb-2'>{tool.title}</h2>
                <p className='text-black text-md'>{tool.description}</p>
              </div>
              <div className="w-full sm:w-1/3">
                <img src={tool.image} alt={tool.alt} className='w-full h-auto object-cover rounded-xl' />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

import { CheckIcon } from '@heroicons/react/20/solid'
import { Link, Navigate, useNavigate } from '@remix-run/react';
import MktLayout from '~/components/layouts/MktLayout';

const includedFeatures = [
  'Unlimited lists',
  'SkillShop tools',
  'Client manager',
  '6.5 % Transaction fee, 3% + $0.25 payment processing fee*',
]

export function Pricing({ id }: { id?: string }) {
  const navigate = useNavigate();
  return (
    <div id={id} className='bg-black px-6'>
      <div className="bg-white py-8 sm:py-24 rounded-3xl">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl sm:text-center">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Simple no-tricks pricing</h2>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              We only want to make money when our customers get real value and benefit from our products, not by limiting features or adding time restrictions.
            </p>
          </div>
          <div className="mx-auto mt-8 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-14 lg:mx-0 lg:flex lg:max-w-none">
            <div className="p-8 sm:p-10 lg:flex-auto">
              <h3 className="text-2xl font-bold tracking-tight text-gray-900">Create your SkillShop</h3>
              <p className="mt-6 text-base leading-7 text-gray-600">
                Your time is valuable and marketplaces take a lot of it, from there it's a race to the bottom pricing. SkillShops are free we only charge a small fee per transaction.
              </p>
              <div className="mt-10 flex items-center gap-x-4">
                <h4 className="flex-none text-sm font-semibold leading-6 text-[#f0003c]">What's included</h4>
                <div className="h-px flex-auto bg-gray-100" />
              </div>
              <ul
                role="list"
                className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
              >
                {includedFeatures.map((feature) => (
                  <li key={feature} className="flex gap-x-3">
                    <CheckIcon aria-hidden="true" className="h-6 w-5 flex-none text-[#f0003c]" />
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
            <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
              <div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
                <div className="mx-auto max-w-xs px-8">
                  <p className="mt-6 flex items-baseline justify-center gap-x-2">
                    <span className="text-5xl font-bold tracking-tight text-gray-900">FREE</span>
                  </p>
                  <PushableButtonWithStyles className='mt-6' onClick={(e) => {
                    e.preventDefault();
                    navigate("/register");
                  }}>
                    Start now
                  </PushableButtonWithStyles>
                  <p className="mt-6 text-xs leading-5 text-gray-600">
                    Invoices and receipts available for easy company reimbursement
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}



const faqs = [
  {
    question: "Is SkillShop only for people with technical skills?",
    answer: "No, SkillShop is designed for anyone with expertise in any field. Whether you're a marketer, writer, consultant, or coach, SkillShop can help you monetize your skills and build your personal brand."
  },
  {
    question: "How do I know if my SkillShop will be profitable?",
    answer: "Our AI helps you create task lists that are optimized for profitability based on your expertise. You can refine these tasks to align with market demand, increasing your chances of success."
  },
  {
    question: "I'm not tech-savvy. Is SkillShop easy to use?",
    answer: "Absolutely! SkillShop is built to be user-friendly. You don't need any technical skills to get started. Our AI and intuitive tools guide you through every step, from creation to managing clients."
  },
  {
    question: "How does SkillShop compare to freelance platforms like Upwork or Fiverr?",
    answer: "Unlike freelance platforms, SkillShop allows you to control your pricing, own client relationships, and avoid high platform fees. You also won't be competing in a saturated marketplace, making it easier to stand out and succeed."
  },
  {
    question: "What if I don't have a large audience or client base?",
    answer: "SkillShops are designed to help you build your personal brand and attract clients. The tools we provide, like the Engagement Booster, help you create compelling content that can draw in your target audience, even if you're starting from scratch."
  },
  {
    question: "How do I maintain control over my client relationships with SkillShop?",
    answer: "SkillShop gives you full ownership of your client relationships, allowing you to communicate directly with them and manage projects without interference from a third-party platform."
  },
  {
    question: "Can I use SkillShop alongside other platforms I'm already on?",
    answer: "Yes, SkillShop can complement your presence on other platforms. You can use it to offer specialized services, build your brand, and maintain higher margins, while still benefiting from other platforms where you might already have a presence."
  },
  {
    question: "What if I'm unsure about the tasks I should offer?",
    answer: "Our Task Refiner tool, powered by AI, helps you identify and refine the best tasks based on your skills. This ensures that what you offer is both relevant and valuable to potential clients."
  },
  {
    question: "Will SkillShop help me if I'm in a niche industry?",
    answer: "Yes, SkillShop is ideal for niche industries. It allows you to highlight your unique skills and differentiate yourself, making it easier to reach the right audience and command higher prices."
  },
  {
    question: "How quickly can I start making money with SkillShop?",
    answer: "You can start generating revenue quickly. Once you set up your SkillShop and refine your tasks, you can begin promoting it immediately. Our tools help you streamline this process, so you can focus on delivering value and earning money."
  },
];


export function FAQs({ id }: { id?: string }) {
  const isHydrated = useHydrated();

  if (!isHydrated) {
    return null; // or a loading spinner, or nothing
  }

  return (
    <div id={id} className="bg-black">
      <div className="mx-auto max-w-7xl px-6 py-24 lg:px-8 ">
        <div className="mx-auto max-w-4xl divide-y divide-white/10">
          <h2 className="text-2xl font-bold leading-10 tracking-tight text-white">Frequently Asked Questions</h2>
          <dl className="mt-10 space-y-6 divide-y divide-white/10">
            {faqs.map((faq, index) => {
              const faqId = `faq-${index}`;
              return (
                <Disclosure key={faqId} as="div" className="pt-6">
                  <dt>
                    <DisclosureButton className="group flex w-full items-start justify-between text-left text-white">
                      <span className="text-base font-semibold leading-7">{faq.question}</span>
                      <span className="ml-6 flex h-7 items-center">
                        <PlusSmallIcon aria-hidden="true" className="h-6 w-6 group-data-[open]:hidden" />
                        <MinusSmallIcon aria-hidden="true" className="h-6 w-6 [.group:not([data-open])_&]:hidden" />
                      </span>
                    </DisclosureButton>
                  </dt>
                  <DisclosurePanel as="dd" className="mt-2 pr-12">
                    <p className="text-base leading-7 text-gray-300">{faq.answer}</p>
                  </DisclosurePanel>
                </Disclosure>
              );
            })}
          </dl>
        </div>
      </div>
    </div>
  );
}



const features = [
  {
    name: 'An Online Store / Marketing Powerhouse',
    description:
      'Your SkillShop is the foundation of your personal brand. It allows you to create a store, manage your clients, and offer tasks that can be monetized.',
    href: '#',
    icon: FaRobot,
  },
  {
    name: 'No Recurring Charges',
    description:
      "Our success is tied to yours. We don't charge you for creating and operating your SkillShop. We only charge per transaction and for advanced tools.",
    href: '#',
    icon: FaCreditCard,
  },
  {
    name: 'No Marketplace Headaches',
    description:
      "You're the master of your Universe. You never have to worry about hidden patterns, marketplace fees, or other complications.",
    href: '#',
    icon: FaHeadSideVirus,
  },
]

export function Guarantee() {
  return (
    <div className="bg-black py-8 pb-36">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        
        <div className="mx-auto mt-10 max-w-2xl lg:max-w-none">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
            {features.map((feature) => (
              <div key={feature.name} className="flex flex-col">
                <dt className="text-base font-semibold leading-7 text-white">
                  <div className="mb-6 flex h-10 w-10 items-center justify-center rounded-lg bg-[#f0003c]">
                    <feature.icon aria-hidden="true" className="h-6 w-6 text-white" />
                  </div>
                  {feature.name}
                </dt>
                <dd className="mt-1 flex flex-auto flex-col text-base leading-7 text-gray-300">
                  <p className="flex-auto">{feature.description}</p>
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}

const footerNavigation = {
  solutions: [
    { name: 'Marketing', href: '#' },
    { name: 'Analytics', href: '#' },
    { name: 'Commerce', href: '#' },
    { name: 'Insights', href: '#' },
  ],
  support: [
    { name: 'Pricing', href: '#' },
    { name: 'Documentation', href: '#' },
    { name: 'Guides', href: '#' },
    { name: 'API Status', href: '#' },
  ],
  company: [
    { name: 'About', href: '#' },
    { name: 'Blog', href: '#' },
    { name: 'Jobs', href: '#' },
    { name: 'Press', href: '#' },
    { name: 'Partners', href: '#' },
  ],
  legal: [
    { name: 'Claim', href: '#' },
    { name: 'Privacy', href: '#' },
    { name: 'Terms', href: '#' },
  ],
  social: [
    // {
    //   name: 'Facebook',
    //   href: '#',
    //   icon: (props) => (
    //     <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
    //       <path
    //         fillRule="evenodd"
    //         d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
    //         clipRule="evenodd"
    //       />
    //     </svg>
    //   ),
    // },
    // {
    //   name: 'Instagram',
    //   href: '#',
    //   icon: (props) => (
    //     <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
    //       <path
    //         fillRule="evenodd"
    //         d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
    //         clipRule="evenodd"
    //       />
    //     </svg>
    //   ),
    // },
    {
      name: 'X',
      href: '#',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path d="M13.6823 10.6218L20.2391 3H18.6854L12.9921 9.61788L8.44486 3H3.2002L10.0765 13.0074L3.2002 21H4.75404L10.7663 14.0113L15.5685 21H20.8131L13.6819 10.6218H13.6823ZM11.5541 13.0956L10.8574 12.0991L5.31391 4.16971H7.70053L12.1742 10.5689L12.8709 11.5655L18.6861 19.8835H16.2995L11.5541 13.096V13.0956Z" />
        </svg>
      ),
    },
    // {
    //   name: 'GitHub',
    //   href: '#',
    //   icon: (props) => (
    //     <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
    //       <path
    //         fillRule="evenodd"
    //         d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
    //         clipRule="evenodd"
    //       />
    //     </svg>
    //   ),
    // },
    {
      name: 'YouTube',
      href: '#',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M19.812 5.418c.861.23 1.538.907 1.768 1.768C21.998 8.746 22 12 22 12s0 3.255-.418 4.814a2.504 2.504 0 0 1-1.768 1.768c-1.56.419-7.814.419-7.814.419s-6.255 0-7.814-.419a2.505 2.505 0 0 1-1.768-1.768C2 15.255 2 12 2 12s0-3.255.417-4.814a2.507 2.507 0 0 1 1.768-1.768C5.744 5 11.998 5 11.998 5s6.255 0 7.814.418ZM15.194 12 10 15V9l5.194 3Z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
  ],
}

export function Footer() {
  return (
    <footer aria-labelledby="footer-heading" className="bg-gray-900 border-t-[1px] border-gray-600">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-24 lg:px-8 lg:pt-32">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="space-y-8">
            <img
              alt="SkillShop"
              src="/logo-skillshop-dark-bkg.svg"
              className="h-12"
            />
            <p className="text-sm leading-6 text-gray-300">
              Lists that make you money.
            </p>
            <div className="flex space-x-6">
              {footerNavigation.social.map((item) => (
                <a key={item.name} href={item.href} className="text-gray-500 hover:text-gray-400">
                  <span className="sr-only">{item.name}</span>
                  <item.icon aria-hidden="true" className="h-6 w-6" />
                </a>
              ))}
            </div>
          </div>
          <div className="mt-16 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold leading-6 text-white">Solutions</h3>
                <ul role="list" className="mt-6 space-y-4">
                  {footerNavigation.solutions.map((item) => (
                    <li key={item.name}>
                      <a href={item.href} className="text-sm leading-6 text-gray-300 hover:text-white">
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-10 md:mt-0">
                <h3 className="text-sm font-semibold leading-6 text-white">Support</h3>
                <ul role="list" className="mt-6 space-y-4">
                  {footerNavigation.support.map((item) => (
                    <li key={item.name}>
                      <a href={item.href} className="text-sm leading-6 text-gray-300 hover:text-white">
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold leading-6 text-white">Company</h3>
                <ul role="list" className="mt-6 space-y-4">
                  {footerNavigation.company.map((item) => (
                    <li key={item.name}>
                      <a href={item.href} className="text-sm leading-6 text-gray-300 hover:text-white">
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-10 md:mt-0">
                <h3 className="text-sm font-semibold leading-6 text-white">Legal</h3>
                <ul role="list" className="mt-6 space-y-4">
                  {footerNavigation.legal.map((item) => (
                    <li key={item.name}>
                      <a href={item.href} className="text-sm leading-6 text-gray-300 hover:text-white">
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-16 border-t border-white/10 pt-8 sm:mt-20 lg:mt-24">
          <p className="text-xs leading-5 text-gray-400">&copy; 2024 SkillShop. All rights reserved.</p>
        </div>
      </div>
    </footer>
  )
}

export function CTA() {
  const navigate = useNavigate();
  return (
    <div className="bg-black pb-36">
      <div className="bg-white mx-auto max-w-7xl px-4 sm:px-6 lg:px-8  py-24  sm:py-24 rounded-3xl">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Launch your <GradientText text="SkillShop"  className='inline-block text-4xl'/> today.
          </h2>
          <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600">
            You don't need to become a YouTuber or start an e-commerce business to make money. All you needs is your skills.
          </p>
          <PushableButtonWithStyles className='mt-8' onClick={(e) => {
            e.preventDefault();
            navigate("/register");
          }}>
            Start now
          </PushableButtonWithStyles>
        </div>
      </div>
    </div>
  )
}






const GradientText = ({ text, className = '', seconds = 2 }) => {
  const gradientColors = [
    '#f30050', '#f8b500', '#00b2a6', '#6593bd', '#7B00FF', '#ff007c',
    // Repeat the gradient to ensure seamless looping
    '#f30050', '#f8b500', '#00b2a6', '#6593bd', '#7B00FF', '#ff007c'
  ];

  const gradientStyle = {
    background: `linear-gradient(75deg, ${gradientColors.join(', ')})`,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    lineHeight: 1.25,
    fontWeight: 'bold',
    backgroundSize: '200% auto',
    animation: `gradientAnimation ${seconds}s linear infinite`,
  };

  const defaultClassName = 'text-2xl'; // default text size
  const mergedClassName = `${defaultClassName} ${className}`; // merge default class with passed class

  return <div style={gradientStyle} className={mergedClassName}>{text}</div>;
};